import { mergeDeep, parseCountry } from '../../utils/common'

import StatusCellRenderer from '../../components/BI/StatusCellRenderer.vue'
import VoiceFieldRenderer from '../../components/BI/VoiceFieldRenderer.vue'
import BooleanCellRenderer from '../../components/BI/BooleanCellRenderer.vue'
import UnitNumberCellRenderer from '../../components/BI/UnitNumberCellRenderer.vue'

export default {
    components: {
        StatusCellRenderer,
        VoiceFieldRenderer,
        UnitNumberCellRenderer,
        BooleanCellRenderer,
    },
    methods: {
        getColumnConfig({ choose, property }){
            return {
                columnType: this.columnTypeRendererMapping,
                columnName: this.columnNameRendererMapping
            }?.[choose]?.[property] ?? {}
        },
        getCellRenderer(columnConfig) {
            let config = mergeDeep(
                this.getColumnConfig({
                    property: columnConfig.column_type,
                    choose: 'columnType'
                }),
                this.getColumnConfig({
                    property: columnConfig.key,
                    choose: 'columnName'
                })
            )
            /**
             * Add context to every cell renderer for more flexibility
             */
            if (config && Object.keys(config)) {
                config = mergeDeep(config, {
                    cellRendererParams: {
                        $caller: this
                    },
                })
            }
            return config ?? {}
        }
    },
    computed: {
        columnTypeRendererMapping() {
            return {
                voice: {
                    cellRenderer: 'VoiceFieldRenderer'
                },
                status: {
                    cellRenderer: 'StatusCellRenderer'
                },
                boolean: {
                    cellRenderer: 'BooleanCellRenderer'
                },
                address: {
                    cellRenderer: (params) => {
                        let address, root;
                        root = params?.colDef?.colId.substring(0,params?.colDef?.colId.lastIndexOf('_'));
                        address = params.value ? params.value + ', ' : null;
                        if(address){
                            if(params.data[root + '_address2']) address += ' #' + params.data[root + '_address2'] + ',';
                            if(params.data[root + '_city'] || params.data[root + '_state'] || params.data[root + '_country'] || params.data[root + '_zip']){
                                address += "<br />";
                                const parsedAddress = {
                                    city: params.data[root + '_city'],
                                    state: params.data[root + '_state'],
                                    country: params.data[root + '_country'],
                                    zip: params.data[root + '_zip']
                                }

                                address += this.$options.filters.formatAddress(parsedAddress);
                            }
                        }
                        return address;
                    }
                },
                concat: {
                    cellRenderer: (params) => {
                        let value
                        let v = (params.value ?? '').split(',');
                        if(!v.length) return '';
                        // BCT: Modify number of values to be displayed.
                        value = v.slice(0, 4).join(', ');
                        if(v.length > 4){
                            value += ' <span style="color: #a0a0a0"> + ' + (v.length - 4) + ' more</span>';
                        }
                        if(params?.colDef?.format == "Recipient"){
                            value = v[0];
                            let customItems = params.value.split(',');
                            value = value == "Local" ? "Local": `<span style="border-bottom: 1px dashed"> ${(customItems.length)} ${col.format}</span>`;
                        }
                        return value
                    }
                    // cellRenderer: 'BIMoreToolTip',
                },
                string: {
                    cellRenderer: (params) => {
                        return  params?.colDef?.format ? `<span style="text-transform: capitalize;">${params.value}</span>` : params.value;
                    }
                }
            }
        },
        columnNameRendererMapping() {
            return {
                //configurations for a specific report type. Can be passed from the report as "column-conf" prop.
                ...this.columnConf,

                //Common configurations. (injected to BI tool for all report types)
                unit_number: {
                    cellRenderer: 'UnitNumberCellRenderer',
                },
                unit_amenities: {
                    cellRenderer: (params) => {
                        let value
                        if(!params.value) return '';
                        let v = params.value.split(',');
                        if(!v.length) return '';
                        // BCT: Modify number of values to be displayed.
                        value = v.slice(0, 4).join(', ');
                        if(v.length > 4){
                            value += ' <span style="color: #a0a0a0"> + ' + (v.length - 4) + ' more</span>';
                        }
                        return value
                    }
                },
                tenant_country: {
                    cellRenderer: (params) => {
                        return parseCountry(params.value)
                    }
                },
                property_country: {
                    cellRenderer: (params) => {
                        return parseCountry(params.value)
                    }
                },
                tenant_military_unit_country: {
                    cellRenderer: (params) => {
                        return parseCountry(params.value)
                    }
                },
                reservation_property_country: {
                    cellRenderer: (params) => {
                        return parseCountry(params.value)
                    }
                },
            }
        }
    }
}
