<template>
  <div class="bulk-edit-align">
    <!-- Added by BCT -->
    <div v-if="template === 'spaces'">
      <hb-modal
        v-model="selectSpace"
        size="medium"
        title="Select spaces in order to update."
        confirmation
        @close="selectSpace = false"
        :footer-off="true"
        show-help-link
      >
        <template v-slot:content>
          <div class="pa-4">
            Select any number of spaces that you want to update.
          </div>
        </template>
      </hb-modal>
      <hb-modal
        v-if="selected"
        v-model="permissionError"
        size="large"
        :title="`Unable to ${selected.title}`"
        confirmation
        @close="permissionError = false"
        :footer-off="true"
        show-help-link
      >
        <template v-slot:content>
          <div class="pa-4">
            <div class="pb-2">The signed in account does not have permission to {{selected.title.toLowerCase()}}. 
            To {{selected.title.toLowerCase()}}, contact your administrator to enable permission.</div>
          </div>
        </template>
      </hb-modal>
      <hb-modal
        v-model="showDiffSpaceTypeModal"
        size="medium"
        :title="`Unable to ${selected.title}`"
        confirmation
        @close="showDiffSpaceTypeModal = false"
        :footer-off="true"
        show-help-link
      >
        <template v-slot:content>
          <div class="pa-4">
            Multiple space types are selected.<br/><br/>
            {{`Select a single space type to ${selected.title} multiple spaces.`}}
          </div>
        </template>
      </hb-modal>
      <div class="border-bottom">
        <p class="font-regular mt-4 px-5 hb-text-night-light">
          {{ getTemplateWiseDescriptionText }}
        </p>
      </div>
      <div class="hb-cloud-lighter">
            <v-flex class="hb-flex">
              <div class="section-content pa-3">
                  <div class="primary-section-content">
                    <v-row class="mx-0">
                        <v-col cols="12" style="transition: 0.3s ease">
                            <div
                              v-for="(route, index) in bulkItems"
                              :key="index"
                              no-gutters
                              :class="{ 'mt-3' : index !== 0 }"
                            >
                                <hb-btn
                                  accordion
                                  @click="sectionClicked(route)"
                                >
                                  {{route.title}}
                                </hb-btn>
                            </div>
                        </v-col>
                      </v-row>
                  </div>
              </div>
            </v-flex>
      </div>
    </div>

    <div v-else-if="isTenantReport()">
      <hb-modal
        v-model="selectSpace"
        size="medium"
        title="Select tenants in order to update."
        confirmation
        @close="selectSpace = false"
        :footer-off="true"
        show-help-link
      >
        <template v-slot:content>
          <div class="pa-4">
            Select any number of tenants that you want to update.
          </div>
        </template>
      </hb-modal>
      <hb-modal
        v-if="selected"
        class="z-300"
        v-model="permissionError"
        size="large"
        :title="`Unable to ${selected.title}`"
        confirmation
        @close="permissionError = false"
        :footer-off="true"
        show-help-link
      >
        <template v-slot:content>
          <div class="pa-4">
            <div class="pb-2">The signed in account does not have permission to {{selected.title.toLowerCase()}}. 
            To {{selected.title.toLowerCase()}}, contact your administrator to enable permission.</div>
          </div>
        </template>
      </hb-modal>
      <div class="border-bottom">
        <p class="font-regular mt-4 px-5 hb-text-night-light">
          {{ getTemplateWiseDescriptionText }}
        </p>
      </div>
      <div class="hb-cloud-lighter">
            <v-flex class="hb-flex">
              <div class="section-content pa-3">
                  <div class="primary-section-content">
                    <v-row class="mx-0">
                        <v-col cols="12" style="transition: 0.3s ease">
                            <v-row justify="center" class="ma-0">
                                <a v-for="(route, index) in getBulkItems()" @click="sectionClicked(route)" class="settings-menu-item hb-larger-font-size font-weight-medium col-12 mb-2 pa-4" :key="index">
                                  {{route.title}} <span class="alignright"><v-icon>mdi-chevron-right</v-icon></span>
                                </a>
                            </v-row>
                        </v-col>
                      </v-row>
                  </div>
              </div>
            </v-flex>
      </div>
    </div> 

    <div v-else>
        <div>
          <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
          <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

          <p class="font-regular mt-2 px-5">

            <strong>Step 1:</strong> Check the items you would like to bulk edit.<br />
            <strong>Step 2:</strong> Select the action you would like to take.
          </p>

        </div>

        <v-container class="hb-table" v-for="(section, i) in sections" :key="i" v-if="options[section]">

          <v-row :key="i" class="hb-table-row">
            <v-col class="hb-table-label pl-6 pt-4" cols="4">
              {{section | capitalize}}
            </v-col>
            <v-col class="hb-table-value">
              <v-radio-group v-model="selected"  class="hb-table-checkbox" hide-detail="auto" dense  :mandatory="false">
                <v-radio  v-for="(action, j) in options[section]" :key="j" hide-details dense :label="action.label" :value="action.action"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      <v-toolbar flat class="panel-footer position-relative">
        <v-spacer></v-spacer>
        <hb-link class="mr-3" @click="$emit('close')">
          Cancel
        </hb-link>

        <hb-btn color="primary" @click="takeAction">
          Next
        </hb-btn>
      </v-toolbar>
    </div>

    <!-- BCT: Added for Delete spaces modal -->
    <delete-spaces
      v-if="showDeleteSpacesModal"
      :items="items"
      :is="'DeleteSpaces'"
      @close="showDeleteSpacesModal = false"
    ></delete-spaces>

    <BulkAssignRentPlan 
      v-model="bulkAssignRentPlan"
      :items="items"
    />
    <BulkApproveRentChange
      ref="bulkApproveRentChange" 
      :items="items"
    />
    <BulkResolveNotificationStatus
      ref="bulkResolveNotificationStatus" 
      :items="items"
    />

    <hb-modal
        confirmation
        v-if="showChangeAreaModal"
        v-model="showChangeAreaModal"
        size="medium"
        :title="'Change Access Area'"
        :footerCancelOption="false"
        show-help-link
      >
        <template v-slot:content>
          <div class="pa-6">
            Access areas of non-vacant spaces can only be changed in the Tenant Profile.
          </div>
        </template>
        
      </hb-modal>
    
  </div>
</template>
<script>
    import api from '../../../assets/api.js';
    import { mapGetters, mapState } from "vuex";
    import DeleteSpaces from './DeleteSpaces.vue';
    import BulkAssignRentPlan from "./BulkAssignRentPlan.vue";
    import BulkApproveRentChange from "./BulkApproveRentChange.vue";
    import BulkResolveNotificationStatus from "./BulkResolveNotificationStatus.vue";

    export default {
        name: "BulkEditIndex",
        data() {
            return {
              bulkItems: [
                    { key: "BulkEditFeaturesAndAmenities", title: "Manage Features and Amenities", permission: 'add_edit_features_amenities_existing_spaces', checkSpaceType: true },
                    { key: "BulkEditUnitDetails", title: "Change the Space Floor", permission: null, field: 'floor' },
                    { key: "BulkEditUnitSellRate", title: "Change the Sell Rate", permission: 'change_set_price_for_spaces' },
                    { key: "BulkEditUnitSetRate", title: "Change the Set Rate", permission: 'change_set_price_for_spaces' },
                    { key: "BulkEditUnitDetails", title: "Change Available Date", permission: null, field: 'available_date' },
                    { key: "BulkEditUnitDetails", title: "Change Website Category", permission: null, field: 'category_id'},
                    { key: "BulkEditUnitDetails", title: "Activate / Deactivate Spaces", permission: 'activate_deactivate_spaces', field: 'activate' },
                    { key: "BulkEditUnitDetails", title: "Change Spaces to Offline / Available", permission: 'edit_status_for_existing_spaces', field: 'status' },
                    { key: "DeleteSpaces", title: "Delete Spaces", permission: 'delete_spaces' },
                    
                ],
                tenantItems: [
                    { key: "BulkAssignRentPlans", title: "Assign Rent Plans", permission: "assign_rent_plan"},
                    { key: "BulkManualRentChange", title: "Change Rent", permission: "manual_rent_changes"},
                    { key: "BulkExemptRentChange", title: "Rent Change Exempt", permission: "exempt_rent_changes" }
                ],
                reviewRentItems: [
                  { key: "BulkEditRentChange", title: "Edit Rent Change", permission: "manual_rent_changes", type: 'rent-change'},
                  { key: "BulkSkipCancel", title: "Skip/Cancel Rent Change", permission: "manage_rent_change_status"},
                  { key: "BulkTagRentChange", title: "Tag Rent Change", permission: "tag_rent_changes"},
                  { key: "BulkApproveRentChange", title: "Approve Rent Change", permission: "approve_rent_changes"},
                  { key: "BulkResolveNotificationStatus", title: "Resolve Notification Status", permission: null, },
                ],

                selectSpace: false,
                permissionError: false,
                showDiffSpaceTypeModal: false,
                selected: '',
                options: {
                    tenant: [
                        {
                            label: 'Send Email',
                            action: 'SendBulkEmail'
                        },
                        {
                            label: 'Send SMS',
                            action: 'SendBulkSMS'
                        },
                        // {
                        //     label: 'Merge Contacts',
                        //     action: 'MergeContacts'
                        // }
                    ],
                    // lease: [
                    //     {
                    //         label: 'Raise Rent',
                    //         action: 'BulkRaiseRent'
                    //     },
                    //     {
                    //         label: 'Add A Fee',
                    //         action: 'BulkChargeFee'
                    //     },
                    //     {
                    //         label: 'Generate a Document',
                    //         action: 'BulkGenerateDocument'
                    //     }
                    // ],
                    // lead: [
                    //
                    //     {
                    //         label: 'Change Lead Status',
                    //         action: 'BulkChangeLeadStatus'
                    //     }
                    // ]
                },
                showDeleteSpacesModal: false,
                showChangeAreaModal: false,
                bulkAssignRentPlan: false,
            }
        },
        props: ['config','template', 'records', 'items'],
        components: {
          DeleteSpaces,
          BulkAssignRentPlan,
          BulkApproveRentChange,
          BulkResolveNotificationStatus
        },
        computed:{
            ...mapGetters({
              hasPermission: 'authenticationStore/rolePermission',
              hasInternalRentEngine:
                "revManStore/hasInternalRentEngine",
              getTenantReportViews: 'reportStore/getTenantReportViews',
              getPropertyRentSettings: "revManStore/getPropertyRentSettings",
              properties: "propertiesStore/filtered"
            }),
            ...mapState({
              rentPlans: (state) => state.revManStore.rent.plans ?? [],
            }),
            sections(){
                return [...new Set(this.config.columns.map(({ group }) => group))]
            },
            getFilteredTenantItems() {
              let permissions = {
                assign_rent_plan: ['hasInternalRentEngine', 'hasRentPlans'] 
              }
              return this.tenantItems.filter(item => {
                let conditions = permissions[item.permission]
                if(!conditions || !conditions.length) return true
                return conditions.every(condition => {
                  if(this[condition] == false) return false
                  return true
                })
              })
            },
            getTemplateWiseDescriptionText() {
              let commonMsg = 'Choose the desired workflow to manage the selected tenants. This will enable you to'
              let descriptions = {
                review_rent_changes: `${commonMsg} edit rent changes, skip, cancel, resolve, approve, and tag rent changes  as needed`
              }
              return descriptions[this.template] ?? `${commonMsg} create rent changes, exempt, and assign rent plans  as needed.`
            },
            hasRentPlans() {
              return this.rentPlans && this.rentPlans.length
            },
        },
        created() {
          api.get(this, api.PROPERTIES + this.properties[0].id + "/property-area-management").then(results => {
            if (results?.property_area_configuration.access_hours_upgrade === 1) {
              this.bulkItems.push({ key: "BulkEditUnitDetails", title: "Change Access Area", permission: null, field: 'area_id'})
            }
          }).catch(error => {});
        },
        methods:{
            takeAction(){
              this.$emit('takeAction', this.selected)
            },
            //added by BCT
            sectionClicked(selected) {
              this.selected = selected;
              if (!this.items || this.items.length == 0) {
                this.selectSpace = true;
                return;
              }
              if (selected.permission && !this.hasPermission(selected.permission)) {
                this.permissionError = true;
                return;
              }
              if (selected.checkSpaceType) {
                if(this.items && this.items.length > 1) {
                  let spaceType = this.items[0].unit_type;
                  let diffSpaceType = this.items.find(item => item.unit_type !== spaceType);
                  if (diffSpaceType) {
                    this.showDiffSpaceTypeModal = true;
                    return;
                  }
                }
              }

              if(selected.field === 'area_id'){
                for (let index = 0; index < this.items.length; index++) {
                      console.log("Unit Status: ", this.items[index].unit_status)
                      if(this.items[index].unit_status !== "Available") {
                        this.showChangeAreaModal = true;
                        return;      
                      }           
                }
              }

              // if (selected.key == 'DeleteSpaces') {
              //   this.showDeleteSpacesModal = true;
              //   return;
              // }

              
              // For handling Popups that doesnot need the usual flow
              switch(selected.key){
                  case 'DeleteSpaces':
                      this.showDeleteSpacesModal = true;
                      return;
                  case 'BulkAssignRentPlans':
                      if (!this.hasPermission('view_rent_plans') || !this.getPropertyRentSettings?.automation_enabled_by_admin) {
                          this.permissionError = true;
                          return;
                      }
                      this.bulkAssignRentPlan = !this.bulkAssignRentPlan;
                      return;
                  case 'BulkApproveRentChange':
                      this.$refs.bulkApproveRentChange.show(this.items);
                      return
                  case "BulkResolveNotificationStatus":
                      this.$refs.bulkResolveNotificationStatus.show(this.items);
                      return;
              }
              this.$emit('takeAction', selected.key, selected)

            },
            isTenantReport() {
              return (this.getTenantReportViews.includes(this.template))
            },
            getBulkItems() {
              return this.template === "review_rent_changes"
                ? this.reviewRentItems
                : this.template === "tenant_rent_management"
                ? this.getFilteredTenantItems
                : [];
            }


        }
    }
</script>
<style scoped>
/* Added by BCT */

    .settings-menu-item{
        text-decoration: none;
        line-height: 24px;
        background-color: #FFFFFF;
        color: #101318;
        border-radius: 4px;
        border: 1px solid #c4cdd5;
    }
    .alignright {
        float: right;
    }
    .bulk-edit-align {
      position: absolute;
      top:0px;
      bottom: 64px;
      width: 100%;
    }
</style>
<style lang="scss">
.z-300 {
  z-index: 300 !important;
}
</style>