<!-- BCT: MOdified for HB-76 -->
<template>
  <div style="position: absolute; top:0; bottom: 64px; width: 100%; ">
    <v-card flat class="edit-panel"  style="height: 100%; overflow-y: auto">
      <div class="border-bottom">
        <p class="font-regular mt-3 px-5 hb-text-night-light" >Select how you want to adjust the price of the selected spaces. </p>
      </div>
      <v-container class="hb-table" v-show="!showConfirm">
          <v-row class="hb-table-row">
            <v-col class="hb-table-label pl-6 pt-4" cols="4">
              Method
            </v-col>
            <v-col class="hb-table-value">
              <v-radio-group  hide-details v-model="form.key" :mandatory="false">
                <v-radio dense label="Set a fixed rate" value="set"></v-radio>
                <v-radio dense label="Increment rate" value="increment"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="hb-table-row" v-if="form.key === 'set'">
            <v-col class="hb-table-label pl-6 pt-4" cols="4">
             Amount
            </v-col>
            <v-col class="hb-table-value">
              <v-text-field
                dense
                flat
                hide-details
                prepend-inner-icon="mdi-currency-usd"
                v-model.lazy="form.value"
                background-color="white"
                single-line
                label="Enter Price Change"
                v-validate="'required|decimal:2|min_value:0'"
                id="Amount"
                name="Amount"
                data-vv-name="Amount"
                :error-messages="errors.collect(`Amount`)"
              >
              </v-text-field>
            </v-col>
          </v-row>

        <div v-if="form.key === 'increment'">
          <v-row class="hb-table-row" >
            <v-col class="hb-table-label pl-6 pt-4" cols="4">
              Price Direction
            </v-col>
            <v-col class="hb-table-value">
              <v-radio-group hide-details v-model="form.direction" mandatory>
                <v-radio dense label="Increase" value="increase"></v-radio>
                <v-radio dense label="Decrease" value="decrease"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="hb-table-row" >
            <v-col class="hb-table-label pl-6 pt-4" cols="4">
              Amount Method
            </v-col>
            <v-col class="hb-table-value">

              <v-radio-group hide-details v-model="form.type" mandatory>
                <v-radio dense label="Dollar Amount" value="dollar"></v-radio>
                <v-radio dense label="Percentage" value="percent"></v-radio>
              </v-radio-group>

            </v-col>
          </v-row>

          <v-row class="hb-table-row">
            <v-col class="hb-table-label pl-6 pt-4" cols="4">
              Amount
            </v-col>
            <v-col class="hb-table-value">
              <v-text-field
                v-if="form.type.toLowerCase() === 'dollar'"
                dense
                flat
                hide-details
                prepend-inner-icon="mdi-currency-usd"
                v-model.lazy="form.value"
                background-color="white"
                single-line
                label="Enter Price Change"
                v-validate="'required|decimal:2|min_value:0'"
                id="dollarAmount"
                name="dollarAmount"
                data-vv-name="dollarAmount"
                :error-messages="errors.collect(`dollarAmount`)"
              >
              </v-text-field>

              <v-text-field
                v-if="form.type.toLowerCase() === 'percent'"
                dense
                flat
                hide-details
                prepend-inner-icon="mdi-percent"
                v-model.lazy="form.value"
                background-color="white"
                single-line
                label="Enter Percent Change"
                v-validate="'required|numeric|min_value:0'"
                id="Amount"
                name="Amount"
                data-vv-name="Amount"
                :error-messages="errors.collect(`Amount`)"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <Rounding></Rounding>
        </div>
      </v-container>

      <v-container class="hb-table" v-show="showConfirm">
        <v-row class="hb-table-row">
          <v-col class="hb-table-label pl-6 pt-4" cols="4">Space</v-col>
          <v-col class="hb-table-value text text-right">Old Price</v-col>
          <v-col class="hb-table-value text text-right">New Price</v-col>
        </v-row>
          <v-row class="hb-table-row" v-for="unit in this.adjustedUnits" :key="unit.id">
            <v-col class="hb-table-label pl-6 pt-4" cols="4">#{{unit.number}}</v-col>
            <v-col class="hb-table-value text text-right">{{unit.price | formatMoney}}</v-col>
            <v-col class="hb-table-value text text-right"><span class="primary-color enhanced">{{ unit.adjusted | formatMoney}}</span></v-col>
          </v-row>
      </v-container>
    </v-card>

    <hb-bottom-action-bar @close="$emit('cancel')" v-show="!showConfirm" class="bottom-bar-position">
      <template v-slot:right-actions>
        <hb-btn color="primary" @click="setEditForm">Next</hb-btn>
      </template>
    </hb-bottom-action-bar>

    <hb-bottom-action-bar :footerCancelOption="false" v-show="showConfirm" class="bottom-bar-position">
      <template v-slot:right-actions>
        <hb-btn color="secondary" @click="showConfirm = false">Back</hb-btn>
        <hb-btn color="primary" :disabled="isLoading($options.name)" @click="save">Change Space Rate</hb-btn>
      </template>
    </hb-bottom-action-bar>

  </div>

</template>

<script>
    import api from '../../../assets/api.js';
    import Status from '../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import Rounding from "../../assets/Rounding.vue";
    import { EventBus } from '../../../EventBus.js';
    import { mapGetters, mapActions } from 'vuex';
    import { notificationMixin } from  '@/mixins/notificationMixin.js';

    export default {
        name: "BulkEditUnitSellRate",
        data() {
            return {
                showConfirm: false,
                price_value: '',
                directions: ['Increase', "Decrease"],
                types: ['Percent', 'Dollars'],
                form:{
                    key: 'set',
                    direction: 'increase',
                    type: 'percent',
                    value: ''
                },
                adjustedUnits: [],
                adjustedUnitsPrices: [],
                roundingData: null

            }
        },
        props: ['items', 'template'],
        mixins: [notificationMixin],

        created(){

            this.getUnitDetails();
            this.fetchCategories();
            this.fetchProducts();
            this.show_dialog = true;
            EventBus.$on("saveRoundingData", this.saveRounding);
        },
        destroyed() {
          EventBus.$off("saveRoundingData", this.saveRounding);
        },
        components: {
            Status,
            Loader,
            HbDatePicker,
            Rounding
        },
        computed:{
            ...mapGetters({
                bulk_edit_items: 'bulkEditStore/items'
            }),
            sections(){
                return [...new Set(this.config.columns.map(({ group }) => group))]
            },
            all_storage(){
                return this.units.filter(u => u.type === 'storage').length === this.units.length;
            },
            all_residential(){
                return this.units.filter(u => u.type === 'residential').length === this.units.length;
            },
            all_parking(){
                return this.units.filter(u => u.type === 'parking').length === this.units.length;
            },
            all_pod(){
                return this.units.filter(u => u.type === 'pod').length === this.units.length;
            }
        },
        methods:{
            ...mapActions({
                resetAction: 'bulkEditStore/resetAction'
            }),
            saveRounding(rounding) {
              this.roundingData = rounding;
            },
            adjustedPrice(price){

                let newPrice = 0;
                let adjustment = 0;

                if(this.form.key === 'set') {
                    newPrice = this.form.value;
                }

                if(this.form.key === 'increment'){
                    switch(this.form.type){
                        case 'percent':
                            adjustment = Math.round(price * this.form.value) / 1e2;
                            break;
                        case 'dollar':
                            adjustment = this.form.value;
                            break;
                    }

                    newPrice = this.form.direction === 'increase' ? +price + +adjustment : +price - +adjustment;
                }

                return newPrice;
            },
            closeWindow(){
                this.resetAction();
            },
            fetchProducts(){
                api.post(this, api.PRODUCTS + 'search', {types: ['rent']}).then(r => {
                    this.products = r.products;
                });
            },
            fetchCategories(){
                api.get(this, api.CATEGORIES).then(r => {
                    this.categories = r.categories;
                });
            },
            async getUnitDetails(){
                let items = this.items.map(item => { return {id:item.unit_id} });
                let response = await api.post(this, api.UNITS + 'bulk-config', {unit_ids: items});
                this.units = response.units || [];
            },
            async setEditForm(){

                this.validate(this).then(async status => {
                    if (!status) {
                      let msg = 'Amount is missing a ' + ((this.form.key === 'set') ? 'price ' : (this.form.type === 'dollar') ? 'price ' : 'percent ') + 'change.';
                      this.showMessageNotification({type: 'error', description: 'There are errors in your form, correct them before continuing.', list: [msg]});
                      return
                    }

                    if (!this.form.key) return;

                    let error = false;
                    await this.setAdjustedUnits(true);
                    await api.put(this, api.UNITS + 'bulk-edit-prices', this.adjustedUnits ).then(r => {
                      this.adjustedUnitsPrices = r;
                    })
                    .catch(err => {
                      this.showMessageNotification({type: 'error', description: 'There are errors in your form, correct them before continuing.', list: [err]});
                      error = true;
                    })
                    if (error) return; 

                    await this.setAdjustedUnits();
                    this.showConfirm = true;
                });
            },
            setAdjustedUnits(dryRun = false) {
              this.adjustedUnits = this.units.map(unit => {
                  return {
                      id: unit.id,
                      number: unit.number,
                      price: unit.price,
                      adjusted: dryRun ? this.adjustedPrice(unit.price) : this.adjustedUnitsPrices.find(u => u.unit_id == unit.id)?.price,
                      rounding: this.roundingData,
                      dryrun: dryRun
                  }
              });
            },
            async save(){
              if(this.isLoading(this.$options.name)) return;
              let response = await api.put(this, api.UNITS + 'bulk-edit-prices', this.adjustedUnits );
              if(!response) return;
              this.showMessageNotification({type: 'success', description: this.getMessage()});
              EventBus.$emit('unit_edited');
              this.$emit('cancel');
            },

            getMessage() {
                let spaces = this.items.map(a => a.unit_number);
                if(this.form.key === 'set') {
                  return 'The set rate has been changed to $' + this.form.value + ' for spaces ' + spaces.join(', ') + '.';
                }

                if(this.form.key === 'increment'){
                    switch(this.form.type){
                      case 'percent':
                        return 'The sell rate has been ' + this.form.direction +'d by ' + this.form.value + '% for spaces ' + spaces.join(', ') + '.';
                      case 'dollar':
                        return 'The sell rate has been ' + this.form.direction +'d by $' + this.form.value + ' for spaces ' + spaces.join(', ') + '.';
                    }
                }
            }
        }
    }

</script>
<style lang="scss" scoped>

  .strike{
    text-decoration: line-through;
    color: #a0a0a0;
  }
  .enhanced{
    font-weight: 500;
  }
  .primary-color{
    color: #00727A;

  }

  .bottom-bar-position {
    ::v-deep .hb-bab-wrapper {
      height: 63px !important;
    }
  }

  .hb-table {
    border-right: 0px !important;
  }

</style>
