<!-- BCT: Modified for EP-97 -->
<template>
  <div>
    <div style="position: absolute; top:0; bottom: 64px; width: 100%;">
      <v-card flat class="edit-panel" style="height: 100%; overflow: auto">
          <div class="border-bottom">
            <p v-if="key === 'available_date'" class="font-regular mt-4 px-5  hb-text-night-light">Select the date to make spaces available.</p>
            <p v-if="key === 'category_id'" class="font-regular mt-4 px-5  hb-text-night-light">Select website category for the spaces.</p>
            <p v-if="key === 'floor'" class="font-regular mt-4 px-5  hb-text-night-light">Set floor value for the spaces.</p>
            <p v-if="!['available_date', 'category_id', 'floor'].includes(key)" class="font-regular mt-4 px-5  hb-text-night-light">Select one of the following options.</p>
          </div>

          <v-container class="px-0 py-0">
            <hb-form :label="fields[key]">
              <v-col class="hb-table-value align-fields">

                <span v-if="key === 'available_date'">

                  <hb-date-picker
                    single-line
                    label="Enter Available Date"
                    :clearable="true"
                    :dense="true"
                    v-model="form.available_date"
                    :solo="true"
                    :flat="true"
                    v-validate="'required'"
                    id="Available Date"
                    name="Available Date"
                    data-vv-name="Available Date"
                    :error-messages="errors.collect(`Available Date`)"
                    hide-details
                    :removePaddingMargin="true"
                    :adjustForContactDetails="true"
                    ></hb-date-picker>
                </span>

                <span v-if="key === 'floor'">
                  <HbTextField
                    single-line
                    label="Enter Space Floor"
                    :clearable="true"
                    v-model="form.floor"
                    v-validate="'required|integer|min_value:-999|max_value:999'"
                    id="Space Floor"
                    name="Space Floor"
                    data-vv-name="Space Floor"
                    :error-messages="errors.collect(`Space Floor`)"
                    hide-details
                    dense
                    flat
                    solo
                  />
                </span>

                <span v-if="key === 'status'">
                  <hb-radio-group
                      v-model="form.status"
                      id="Status"
                      name="Status"
                      data-vv-name="Status"
                      data-vv-as="Status"
                      :error-messages="errors.collect('Status')"
                      v-validate="'required'"
                      hide-details
                    >
                      <hb-radio  v-for="(status, i) in statuses" :key="i" :value="status.value">
                        <template v-slot:label>
                          <span>{{status.text}}</span>
                        </template>
                      </hb-radio>
                    </hb-radio-group>
                    <hb-modal
                        confirmation
                        v-if="showStatusConfirmation"
                        v-model="showStatusConfirmation"
                        size="medium"
                        :title="`Change Spaces to ${form.status ? 'Available': 'Offline'}`"
                        :footerCancelOption="false"
                        @close="showStatusConfirmation = false"
                        show-help-link
                      >
                        <template v-slot:content>
                          <div class="pa-6">
                            {{`Changing spaces to ${form.status ? 'Available': 'Offline'} will ${form.status ? 'add': 'remove'} all selected spaces ${form.status ? 'to': 'from'} the available inventory.`}}
                          </div>
                          <div class="pl-6 pb-6">
                            {{`Are you sure you want to change all the selected spaces to ${form.status ? 'Available': 'Offline'}?`}}
                          </div>
                        </template>
                        <template v-slot:right-actions>
                          <hb-btn @click="save(true)" color="primary">{{`Change ${items.length} Space${items.length > 1 ? 's' : ''}`}}</hb-btn>
                        </template>
                    </hb-modal>
                </span>

                <span v-if="key === 'category_id'" class="website-category-field">
                    <v-select
                      single-line
                      label="Select Website Category"
                      hide-details
                      :items="categories"
                      v-model="form.category_id"
                      item-value="id"
                      item-text="name"
                      v-validate="'required'"
                      id="Website Category"
                      name="Website Category"
                      data-vv-name="Website Category"
                      :error-messages="errors.collect(`Website Category`)"
                    >
                  </v-select>
                </span>

                <span v-if="key === 'area_id'" class="website-category-field">
                    <v-select
                      single-line
                      label="Select Access Area"
                      hide-details
                      :items="accessAreas"
                      v-model="form.area_id"
                      item-value="id"
                      item-text="name"
                      v-validate="'required'"
                      id="Access Area"
                      name="Access Area"
                      data-vv-name="Access Area"
                      :error-messages="errors.collect(`Access Area`)"
                    >
                  </v-select>
                </span>

                <span v-if="key === 'description'">
                  <v-textarea
                    single-line
                    label="Enter description"
                    dense
                    flat
                    hide-details
                    v-model="form.description"
                    class="custom-v-textarea-wrapper"
                  ></v-textarea>
                </span>

                <span v-if="key === 'featured'">
                  <v-radio-group
                      dense
                      flat
                      hide-details
                      v-model="form.featured" :mandatory="true" row>
                      <v-radio hide-details dense label="Yes" :value="1"></v-radio>
                      <v-radio hide-details dense label="No" :value="0"></v-radio>
                  </v-radio-group>
                </span>

                <span v-if="key === 'product_id'">
                  <v-select
                    single-line
                    label="Choose Product"
                    dense
                    flat
                    hide-details
                    :items="products"
                    v-model="form.product_id"
                    item-value="id"
                    item-text="name"
                    v-validate="'required'"
                  >
                  </v-select>
                </span>
              
                <span v-if="key === 'activate'">
                  <!-- Added by BCT -->
                  <hb-radio-group
                      v-model="form.activate"
                      id="activeDeactive"
                      name="activeDeactive"
                      data-vv-name="activeDeactive"
                      data-vv-as="activeDeactive"
                      :error-messages="errors.collect('activeDeactive')"
                      v-validate="'required'"
                      hide-details
                    >
                      <hb-radio :value="1">
                        <template v-slot:label>
                          Activate Spaces
                        </template>
                      </hb-radio>
                      <hb-radio :value="0">
                        <template v-slot:label>
                          Deactivate Spaces
                        </template>
                      </hb-radio>
                    </hb-radio-group>
                    <hb-modal
                        confirmation
                        v-if="showActivateConfirmation"
                        v-model="showActivateConfirmation"
                        size="medium"
                        :title="`${form.activate ? 'Activate': 'Deactivate'} Spaces`"
                        :footerCancelOption="false"
                        @close="showActivateConfirmation = false"
                        show-help-link
                      >
                        <template v-slot:content>
                          <div class="pa-6">
                            {{`${form.activate ? 'Activating': 'Deactivating'} the spaces will ${form.activate ? 'add': 'remove'} all selected spaces ${form.activate ? 'to': 'from'} the available inventory.`}}
                          </div>
                          <div class="pl-6 pb-6">
                            {{`Are you sure you want to ${form.activate ? 'activate': 'deactivate'} all the selected spaces?`}}
                          </div>
                        </template>
                        <template v-slot:right-actions>
                          <hb-btn @click="activateDeactivateSpaces()" color="primary">{{`${form.activate ? 'Activate': 'Deactivate'} ${items.length} Space${items.length > 1 ? 's' : ''}`}}</hb-btn>
                        </template>
                    </hb-modal>
                </span>
              </v-col>
            </hb-form>
          </v-container>
      </v-card>

      <div class="bottom-bar-position">
        <hb-bottom-action-bar @close="back()">
          <template v-slot:right-actions>
            <hb-btn color="primary" :disabled="!key" @click="save">Save</hb-btn>
          </template>
        </hb-bottom-action-bar>
      </div>
    </div>

  </div>
<!--    <v-card flat style="height: 100%; overflow: scroll;" v-if="showEditForm">-->
<!--      <div>-->
<!--        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>-->
<!--        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>-->
<!--        <p class="font-regular mt-2 px-5" >Edit Unit Details.</p>-->
<!--      </div>-->
<!--      <v-container class="hb-table" >-->
<!--        <v-row class="hb-table-row">-->

<!--          <v-col class="hb-table-label pl-6 pt-4" cols="4">-->
<!--            Choose what to edit-->
<!--          </v-col>-->
<!--          <v-col class="hb-table-value">-->

<!--            <v-radio-group v-model="key" :mandatory="false">-->
<!--              <v-radio hide-details dense class="pa-0 ma-0" label="Available Date" value="available_date"></v-radio>-->
<!--              <v-radio hide-details dense class="pa-0 ma-0" label="Status" value="status"></v-radio>-->
<!--              <v-radio hide-details dense class="pa-0 ma-0" label="Category" value="category_id"></v-radio>-->
<!--              <v-radio hide-details dense class="pa-0 ma-0" label="Description" value="description"></v-radio>-->
<!--              <v-radio hide-details dense class="pa-0 ma-0" label="Featured" value="featured"></v-radio>-->
<!--              <v-radio hide-details dense class="pa-0 ma-0" label="Rent Product" value="product_id"></v-radio>-->
<!--            </v-radio-group>-->

<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-container>-->

<!--    </v-card>-->
<!--    <v-toolbar flat class="panel-footer">-->
<!--      <v-spacer></v-spacer>-->
<!--      <v-btn color="secondary" @click="$emit('close')" class="mr-2">-->
<!--        Cancel-->
<!--      </v-btn>-->

<!--      <v-btn color="primary" :disabled="!key" @click="setEditForm">Next</v-btn>-->
<!--      &lt;!&ndash;      <v-btn color="primary" class="mr-2" @click="saveReport">&ndash;&gt;-->
<!--      &lt;!&ndash;        Save Report&ndash;&gt;-->
<!--      &lt;!&ndash;      </v-btn>&ndash;&gt;-->
<!--    </v-toolbar>-->


<!--  </div>-->




<!--                <v-col class="hb-table-value ma-0" v-show="showEditForm">-->

<!--                  <span v-show="key === 'available_date'">-->
<!--                    <hb-date-picker :dense="true" v-model="form.available_date"></hb-date-picker>-->
<!--                  </span>-->

<!--                  <span v-show="key === 'status'">-->
<!--                    <v-select :dense="true" v-model="form.status" :items="statuses" ></v-select>-->
<!--                  </span>-->

<!--                  <span v-show="key === 'category_id'">-->
<!--                      <v-select-->
<!--                        :items="categories"-->
<!--                        v-model="form.category_id"-->
<!--                        item-value="id"-->
<!--                        item-text="name"-->
<!--                      >-->
<!--                    </v-select>-->
<!--                  </span>-->

<!--                  <span v-show="key === 'description'">-->
<!--                    <v-textarea :dense="true" v-model="form.description" :items="statuses" ></v-textarea>-->
<!--                  </span>-->

<!--                  <span v-show="key === 'featured'">-->
<!--                    <v-radio-group v-model="form.featured" :mandatory="true" class="py-0 px-3 ma-0">-->
<!--                      <v-radio hide-details dense class="pa-0 ma-0" label="Yes" :value="1"></v-radio>-->
<!--                      <v-radio hide-details dense class="pa-0 ma-0" label="No" :value="0"></v-radio>-->
<!--                   </v-radio-group>-->
<!--                  </span>-->

<!--                  <span v-show="key === 'product_id'">-->
<!--                    <v-select-->
<!--                      :items="products"-->
<!--                      v-model="form.product_id"-->
<!--                      item-value="id"-->
<!--                      item-text="name"-->
<!--                    >-->
<!--                    </v-select>-->
<!--                  </span>-->
<!--&lt;!&ndash;                  <span v-show="key === 'size'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-col><v-text-field v-model="form.width" v-validate="'decimal:2|min_value:0|max_value:99'" @focus="$event.target.select()"></v-text-field></v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-col>W x</v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-col><v-text-field v-model="form.length" v-validate="'decimal:2|min_value:0|max_value:99'" @focus="$event.target.select()"></v-text-field></v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-col>L x</v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-col><v-text-field v-model="form.height" v-validate="'decimal:2|min_value:0|max_value:99'" @focus="$event.target.select()"></v-text-field></v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-col>H</v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                    </v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                  </span>&ndash;&gt;-->
<!--                  <v-btn color="secondary" @click="showEditForm = false">< Back</v-btn>-->
<!--                  <v-btn color="primary" :disabled="!key" @click="save">Save</v-btn>-->
<!--                </v-col>-->


</template>

<script>
    import api from '../../../assets/api.js';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import { EventBus } from '../../../EventBus.js';
    import { mapGetters, mapActions } from 'vuex';
    import { notificationMixin } from  '@/mixins/notificationMixin.js';

    export default {
        name: "BulkEditUnitDetails",
        data() {
            return {
              showStatusConfirmation: false,
              showActivateConfirmation: false,
              show_dialog: false,
              units: [],
              products: [],
              categories:[],
              accessAreas: [],
              key: '',
              statuses: [
                {
                  text: 'Available Spaces',
                  value: 1,
                },
                {
                    text: 'Offline Spaces',
                    value: 0,
                }
              ],
              form:{
                  available_date: '',
                  status: '',
                  category_id: '',
                  description: '',
                  featured: '',
                  product_id: '',
                  width: '',
                  length: '',
                  height: '',
                  floor: '',
                  activate: ''
              },
              fields:{
                available_date: 'Available Date',
                status: 'Available / Offline',
                category_id: 'Website Category',
                description: 'Description',
                featured: 'Featured',
                product_id: 'Rent Product',
                activate: 'Activate / Deactivate',
                floor: 'Space Floor',
              }
            }
        },
        props: ['items', 'selected'],
        mixins:[ notificationMixin],
        created(){

            this.getUnitDetails();
            this.fetchCategories();
            this.fetchProducts();
            this.fetchAccessAreas();
            this.show_dialog = true;
            // Added by BCT
            this.key = this.selected.field;
        },
        components: {
            HbDatePicker
        },
        computed:{
            ...mapGetters({
                bulk_edit_items: 'bulkEditStore/items',
                properties: "propertiesStore/filtered"
            }),
            sections(){
                return [...new Set(this.config.columns.map(({ group }) => group))]
            },
            all_storage(){
                return this.units.filter(u => u.type === 'storage').length === this.units.length;
            },
            all_residential(){
                return this.units.filter(u => u.type === 'residential').length === this.units.length;
            },
            all_parking(){
                return this.units.filter(u => u.type === 'parking').length === this.units.length;
            },
            all_pod(){
                return this.units.filter(u => u.type === 'pod').length === this.units.length;
            }
        },
        methods:{
            ...mapActions({
                resetAction: 'bulkEditStore/resetAction'
            }),
            closeWindow(){
                this.resetAction();
            },
            fetchProducts(){
                api.post(this, api.PRODUCTS + 'search', {types: ['rent']}).then(r => {
                    this.products = r.products;
                });
            },
            fetchCategories(){
                api.get(this, api.CATEGORIES).then(r => {
                    this.categories = r.categories;
                });
            },
            async fetchAccessAreas(){
              await api.get(this, api.PROPERTIES + this.properties[0].id + "/areas", {active: 1}).then(r => {
                this.accessAreas = r;
              });
            },
            async getUnitDetails(){
                let items = this.items.map(item => { return {id:item.unit_id} });
                let response = await api.post(this, api.UNITS + 'bulk-config', {unit_ids: items});
                this.units = response.units || [];
            },
            // Added by BCT
            async activateDeactivateSpaces() {
               let payload = 
               {
                  "deactivate": !this.form.activate,
                  "units": this.items.map(unit => ({id: unit.unit_id, status: unit.unit_status})),
                  "propertyId": this.properties[0].id
              }
              let spacesNumbers = this.items.map(a => a.unit_number);
              await api.put(this, api.ACTIVATE_DEACTIVATE_SPACES, payload).then(r => {
                  let desc = `Spaces ${spacesNumbers.join(', ')} have been ${this.form.activate ? 'Activated' : 'Deactivated'}.`;
                  this.showMessageNotification({type: 'success', description: desc});
              }).catch(err => {
                let errList = [];
                let errMsg = err;
                if (err.leased && err.leased.length) {
                    errList = err.leased;
                    errMsg = err.message;
                }
                this.showMessageNotification({type: 'error', description: errMsg, list: errList});
              })
              this.showActivateConfirmation = false;
              EventBus.$emit('unit_edited');
              this.$emit('cancel');
            },
            async save(statusConfirmed){
                let spacesNumbers = this.items.map(a => a.unit_number);
                let status = await this.validate(this)
                if (!status) return;
                if(this.key === 'status' && !statusConfirmed) {
                  this.showStatusConfirmation = true;
                  return;
                } else if(this.key === 'activate') {
                  this.showActivateConfirmation = true;
                  return;
                }
                let data = {
                    unit_ids: this.items.map(item => {
                      return {
                        id: item.unit_id
                      }
                    }),
                    form: {}
                };
                data.form[this.key] = this.form[this.key];
                let response = await api.put(this, api.UNITS + 'bulk-edit', data ).catch(err => {
                  this.showMessageNotification({type: 'error', description: err});

                })
                if(!response) return;
                //Added by BCT
                let msg = `Spaces ${spacesNumbers.join(', ')} have been updated.`
                if (this.key === 'status'){
                  msg = `Spaces ${spacesNumbers.join(', ')} have been moved to ${ this.form.status === 1 ? 'Available' : 'Offline'}.`
                }
                this.showMessageNotification({type: 'success', description: msg});
                EventBus.$emit('unit_edited');
                this.$emit('cancel');
            },
            // Added by BCT
            back() {
              this.$emit('cancel');
            },

        },
        // Added by BCT
        watch: {
          "errors.items"(val) {
            if(val && val.length) {
              if (val[0].field === "Status" && val[0].rule === "required" ) {
                  val[0].msg = "Available / Offline is missing a selected value."
              }
              else if (val[0].field === "activeDeactive" && val[0].rule === "required" ) {
                  val[0].msg = "Activate / Deactivate is missing a selected value."
              }
              let desc = 'There are errors in your form, correct them before continuing.';
              this.showMessageNotification({type: 'error', description: desc, list: val});
            }
          }
        }
    }

</script>

<style scoped lang="scss">
  .align-fields {
    padding-top : 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }

  .website-category-field {
    .v-select {
      padding-top: 7px !important;
    }
  }

  .bottom-bar-position {
    position: relative;
    top: 4px;
  }
</style>