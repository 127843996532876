import { render, staticRenderFns } from "./FeaturesAndAmenities.vue?vue&type=template&id=f6ec1d76&scoped=true&"
import script from "./FeaturesAndAmenities.vue?vue&type=script&lang=js&"
export * from "./FeaturesAndAmenities.vue?vue&type=script&lang=js&"
import style0 from "./FeaturesAndAmenities.vue?vue&type=style&index=0&id=f6ec1d76&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6ec1d76",
  null
  
)

export default component.exports