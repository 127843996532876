<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<template>
  <div class="hb-flex-align status-bar">
    <div class="spaces-count-section" v-if="totalCount">
      <label class="hb-text-night hb-font-caption-medium" :class="{ 'pr-1' : filteredCount === totalCount }">{{ title }}:</label>
      <span>
        <span class="hb-text-night hb-font-caption" v-if="filteredCount != totalCount">
          {{ filteredCount }}
        </span>
        <span class="hb-text-night hb-font-caption-medium" v-if="filteredCount != totalCount">
          of
        </span>
        <span class="hb-text-night hb-font-caption">{{ totalCount }}</span>
      </span>
    </div>

    <div class="ml-2" v-if="active_action === 'bulk_edit'">
      <label class="hb-text-night hb-font-caption-medium">Selected:</label>
      <span class="hb-text-night hb-font-caption ml-1">{{ selected.length }}</span>
    </div>

    <div class="hb-flex-align" v-if="filteredValues && filteredValues.length">
      <div class="ml-6 chips-section">
        <hb-chip
          class="ag-footer-chip-fix"
          v-for="(filter, i) in filteredValues"
          :key="i + filter"
          editable
          @click="removeFilter(filter)"
        >
          <span v-if="filter.key">
            <span>{{ filter.key }}</span>
            <span v-if="filter.value"> ({{ filter.value }})</span>
          </span>
        </hb-chip>
      </div>
    </div>

    <div
      class="hb-flex-align reset-filter-section"
      v-if="filteredValues && filteredValues.length"
    >
      <hb-btn small color="secondary" @click="resetFilter" class="reset-link">Reset Filters</hb-btn>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "Footer",
  data() {
    return {
      params: "",
      title: "",
      screens: {
        spaces: "Spaces",
        active_tenants: "Tenants",
        active_leads: "Leads",
        space_group_spaces: "Spaces",
        tenant_rent_management: "Tenants"
      },
      totalCount: 0,
      filteredCount: 0,
      filteredValues: [],
      footerParams: null,
      selected: 0,
      active_action: '',
    };
  },

  created() {},

  methods: {
    setFooterParams(count, screen, totalCount, footerParams) {
      this.title = this.screens[screen];
      this.filteredCount = count;
      this.totalCount = totalCount;
      this.footerParams = cloneDeep(footerParams);
      this.getFilteredValues();
    },

    getFilteredValues() {
      this.filteredValues = [];
      if (this.footerParams && this.footerParams.filters && this.footerParams.filters.columns && this.footerParams.filters.columns.length) {
        this.footerParams.filters.columns.map(col => {
          if (col.input === "text" && col.search) {
            this.filteredValues.push({
              colId: col.key,
              key: col.search,
              value: "",
              search: col.search,
              data: col.search
            });
          }

          if (col.input === "multi-select" && col.search.length) {
            let data = this.$store.getters['filterStore/'+ col.key];
            col.search.map(f => {
              this.filteredValues.push({
                colId: col.key,
                key: (data && data.length) ? data.find(a => a.id === f).name : f,
                value: "",
                search: col.search,
                data: f
              });
            });
          }

          if (col.input === "multi-select-amenities" && col.search.length) {
            col.search.map(f => {
              this.filteredValues.push({
                colId: col.key,
                key: f.key,
                value: f.value,
                search: col.search,
                data: f
              });
            });
          }

          if (col.input === "boolean" && ["1", "0"].includes(col.search)) {
            this.filteredValues.push({
              colId: col.key,
              key: col.label,
              value: col.search === "1" ? "Yes" : "No",
              search: col.search,
              data: col.search
            });
          }

          if (
            (col.input === "timeframe" || col.input === "date") &&
            col.search.label
          ) {
            this.filteredValues.push({
              colId: col.key,
              key: col.search.label,
              value:
                col.search.label === "Custom Range"
                  ? col.search.start_date + " To " + col.search.end_date
                  : "",
              search: col.search,
              data: col.search
            });
          }

          if (col.input === "comparison" && col.search.operator) {
            this.filteredValues.push({
              colId: col.key,
              key: col.search.operator,
              value:
                col.search.value + (col.search.max ? "-" + col.search.max : ""),
              search: col.search,
              data: col.search
            });
          }
        });
      }
    },

    removeFilter(rmv) {
      this.filteredValues.splice(this.filteredValues.indexOf(rmv), 1);
      this.footerParams.filters.columns.map(col => {
        if (rmv.colId === col.key) {
          if (["multi-select", "multi-select-amenities"].includes(col.input)) {
            col.search.splice(col.search.indexOf(rmv.data), 1);
            this.setFilter(rmv.colId, col.search);
          }

          if (["text", "boolean"].includes(col.input)) {
            this.setFilter(rmv.colId, "");
          }
          if (["timeframe", "date"].includes(col.input)) {
            let initValue = this.init(col.input);
            this.setFilter(rmv.colId, initValue);
          }

          if (col.input === "comparison") {
            let initValue = this.init(col.input);
            this.setFilter(rmv.colId, initValue);
          }
        }
      });
    },

    async setFilter(key, filteredValues) {
      if (!this.params.api || !this.footerParams) return;

      // dispatch filtered data
      this.$store.commit("reportStore/setColumnFilter", {
        report_id: this.footerParams.id,
        report_template: this.footerParams.template,
        key: key,
        search: filteredValues
      });

      // Update grid
      this.params.api.onFilterChanged();
    },

    resetFilter() {
      if (this.footerParams && this.footerParams.filters && this.footerParams.filters.columns && this.footerParams.filters.columns.length) {
        this.footerParams.filters.columns.map(col => {
          let initValue = this.init(col.input);
          this.setFilter(col.key, initValue);
        });
      }
      this.filteredValues = [];
    },

    init(type) {
      let filter_value;
      switch (type) {
        case null:
        case "text":
        case "radio":
        case "dropdown":
          filter_value = "";
          break;
        case "multi-select":
        case "list":
        case "multi-select-amenities":
          filter_value = [];
          break;
        case "checkbox":
          filter_value = false;
          break;
        case "comparison":
          filter_value = {
            operator: "",
            value: "",
            max: ""
          };
          break;
        case "date":
        case "timeframe":
          filter_value = {
            label: "",
            days: 0,
            period: "",
            relation: "",
            start_date: "",
            end_date: ""
          };
          break;
      }
      return filter_value;
    },

    setSelectedRowsCount(active_action, selectedRows) {
      this.active_action = active_action;
      this.selected = selectedRows;
    }
  },
  destroyed() {}
};
</script>

<style lang="scss" scoped>
.spaces-count-section {
  white-space: nowrap;
}
.status-bar {
  min-height: 48px;
  margin-left: -16px;
}
.chips-section {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  padding: 8px 0px 8px 0px;
  max-height: 250px;
  overflow-y: auto;
}
.chips {
  height: 35px;
  display: flex;
  align-items: center;
}
.chip {
  border: 1.5px solid #06a9b4;
  border-radius: 12px;
  padding: 3px 5px 3px 5px;
  color: #06a9b4;
}
.reset-filter-section {
  min-width: 85px;
}
.reset-link {
  right: 12px;
  position: absolute;
}

</style>

<style>
.chips-section .hb-chip.ag-footer-chip-fix .v-chip__content {
  font-weight: normal;
  padding-top: 2.5px;
}

.report-viewer-container .ag-status-bar {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
