<template>
  <div class="bulk-align">
    <div style="padding: 15px 15px 0;" v-if="errorHas($options.name) || successHas($options.name) ">
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
    </div>

    <div class="border-bottom py-3" v-if="['spaces', 'tenant_rent_management', 'review_rent_changes'].includes(template) && state !== 'initial' && !config.pivot_mode.type">
      <span class="ml-3 font-weight-medium hb-text-night">
        <span @click="setInitialState" class="hand-pointer">
          <v-icon size="25" color="hb-text-night" class="hand-pointer">mdi-chevron-left</v-icon>
          <label class="tab-label hand-pointer">{{selectedSection.title}}</label>
        </span>
      </span>
    </div>

    <div style="position: relative; height: calc(100% - 50px)">
      <index v-if="state === 'initial' && !config.pivot_mode.type"  @close="$emit('close')" :items="items" :config="config" :template="template" @takeAction="takeAction" ></index>

      <component
        @cancel="setInitialState"
        v-if="state !== 'initial' && !config.pivot_mode.type"
        :items="items"
        :is="state"
        :template="template"
        :selected="selectedSection"
      />

      <div v-if="config.pivot_mode.type" class="ma-3">
        <hb-notification type="caution" not-dismissable>
          Bulk Edit is not currently available when data is grouped.
        </hb-notification>
      </div>
    </div>

  </div>
</template>
<script>
    import Status from '../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';

    import Vue from 'vue';

    import BulkEditUnitDetails from '../BulkActions/UnitDetails.vue';
    import BulkEditUnitAttributes from '../BulkActions/UnitAttributes.vue';
    import BulkEditUnitSellRate from '../BulkActions/UnitPrices.vue';
    import BulkEditUnitSetRate from '../BulkActions/UnitSetRate.vue';

    import MergeContacts from '../BulkActions/MergeContacts.vue';
    import BulkChargeFee from '../BulkActions/ChargeFee.vue';
    import SendBulkEmail from '../BulkActions/SendEmail.vue';
    import SendBulkSMS from '../BulkActions/SendSms.vue';
    import BulkRaiseRent from '../BulkActions/RaiseRent.vue';
    import BulkGenerateDocument from '../BulkActions/GenerateDocument.vue';
    import BulkChangeLeadStatus from '../BulkActions/LeadStatus.vue';
    import Index from '../BulkActions/Index.vue';
    import BulkEditFeaturesAndAmenities from '../BulkActions/FeaturesAndAmenities.vue';
    import BulkManualRentChange from '../BulkActions/ManualRentChange.vue';
    import BulkEditRentChange from '../BulkActions/BulkEditRentChange.vue';
    import BulkSkipCancel from '../BulkActions/BulkSkipCancel.vue';
    import BulkTagRentChange from '../BulkActions/BulkTagRentChange.vue';
    import BulkExemptRentChange from '../BulkActions/ExemptRentChange.vue';

    Vue.component('BulkEditUnitDetails', BulkEditUnitDetails);
    Vue.component('BulkEditUnitAttributes', BulkEditUnitAttributes);
    Vue.component('BulkEditUnitSellRate', BulkEditUnitSellRate);
    Vue.component('BulkEditUnitSetRate', BulkEditUnitSetRate);
    Vue.component('MergeContacts', MergeContacts);
    Vue.component('SendBulkEmail', SendBulkEmail);
    Vue.component('SendBulkSMS', SendBulkSMS);
    Vue.component('BulkRaiseRent', BulkRaiseRent);
    Vue.component('BulkChargeFee', BulkChargeFee);
    Vue.component('BulkGenerateDocument', BulkGenerateDocument);
    Vue.component('BulkChangeLeadStatus', BulkChangeLeadStatus);
    Vue.component('BulkEditFeaturesAndAmenities', BulkEditFeaturesAndAmenities);
    Vue.component('BulkManualRentChange', BulkManualRentChange);
    Vue.component('BulkEditRentChange', BulkEditRentChange);
    Vue.component('BulkSkipCancel', BulkSkipCancel);
    Vue.component('BulkTagRentChange', BulkTagRentChange);
    Vue.component('BulkExemptRentChange', BulkExemptRentChange);

    export default {
        name: "BulkEdit",
        data() {
            return {
                state: 'initial',
                selected: '',
                selectedSection: {},
            }
        },
        props: ['config','template', 'records', 'items'],
        components: {
            Status,
            Loader,
            Index
        },

        methods:{
            setInitialState(){
                this.state = 'initial';
                this.$emit('disableCheckboxes', false);
            },

            takeAction(selected, section = {}){

                if(!selected) {
                    this.errorSet(this.$options.name, "Please select an action to take");
                    return;
                }

                if(!this.items.length) {
                    this.errorSet(this.$options.name, "Please select some records to edit");
                    return;
                }

                this.selected = selected;
                this.state = this.selected;
                this.selectedSection = section;

                this.$emit('disableCheckboxes', true);

            }
        },
    }

</script>
<style scoped>
  .bulk-align {
    position: absolute;
    top: 53.5px;
    bottom: 0;
    padding: 0;
    width: 100%;
  }

  .tab-label {
    position: relative;
    top: 2px;
  }
</style>
