<script>

export default {
    computed: {
        unitNumber() {
            return this.params.value ? `#${this.params.value}` : '-'
        }
    }
}
</script>

<template>
    <div>
        <HbUnitIcon
            :unit="params.data"
            small
            color='#101318'
            class="pr-1"
        />
        <span>{{ unitNumber }}</span>
    </div>
</template>