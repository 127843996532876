<!-- New file created by BCT for space-management -->
<template>
  <div>
    <hb-modal
      size="large"
      :title="getModalTitle"
      v-model="dialog"
      @close="onCloseConfirmationDialog"
      :footerCancelOption="true"
      show-help-link
    >
      <!-- Main content -->
      <template v-slot:content>
        <hb-modal
          v-model="showConfirmationDialog"
          size="medium"
          title="Discard Setup"
          confirmation
          show-help-link
        >
          <!-- Confirmation Modal -->
          <template v-slot:content>
            <div class="px-6 py-4">
              Are you sure you want to discard this setup?
            </div>
            <div class="px-6 pb-4">
              If you do, you will lose all the information you’ve entered.
              Continue the setup process to ensure Hummingbird saves your
              information.
            </div>
          </template>
          <template v-slot:right-actions>
            <hb-btn color="primary" @click="$emit('close')">Discard</hb-btn>
          </template>
        </hb-modal>
        <!-- Tabs -->
        <hb-stepper v-model="stepper">
          <hb-stepper-header>
            <hb-stepper-step
              @click="goToStep(1)"
              :complete="steps[0].status == 'step-complete'"
              :rules="[() => steps[0].status !== 'step-error']"
              key="1-step"
              step="1"
            >
              Create Space Mix
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(2)"
              :complete="steps[1].status == 'step-complete'"
              :rules="[() => steps[1].status !== 'step-error']"
              key="2-step"
              step="2"
            >
              Apply Features and Amenities
            </hb-stepper-step>
          </hb-stepper-header>

          <v-stepper-items>
            <v-stepper-content class="pa-0" key="1-content" step="1">
                <template>
                  <div class="px-4 py-4 text-grey">
                    Enter your space numbers using the space range and/or space
                    list.
                  </div>
                  <v-col cols="12" class="pa-0">
                    <v-divider></v-divider>
                  </v-col>
                </template>
                <div v-for="(field, i) in spaceField" :key="i">
                  <hb-form :label="field.name" :divider="i === spaceField.length - 1 ? false : true" :required="field.required" :tooltip="!!field.tooltip" :tooltip-body="field.tooltip" :full="field.name == 'Add Spaces'">
                    <v-select
                      v-if="field.name == 'Space Type'"
                      :items="spaceTypes"
                      item-text="name"
                      item-value="value"
                      label="Select a space type"
                      v-model="spaceDetails.space_type"
                      name="Space Type"
                      id="Space Type"
                      :error-messages="errors.collect('Space Type')"
                      data-vv-name="Space Type"
                      data-vv-as="Space Type"
                      v-validate="'required'"
                      hide-details
                      single-line
                      @change="fetchwebsiteCategories()"
                    >
                    </v-select>
                    <div class="pa-0" v-else-if="field.name == 'Size'">
                      <v-row v-for="(sizeField, sizeI) in sizeFields" :key="sizeI">
                        <v-col cols="2" :class="['pt-0 pr-0', sizeI === sizeFields.length - 1 ? 'pb-0' : 'pb-2']">
                          {{ sizeField }}:
                        </v-col>
                        <v-col cols="2" :class="['pt-0 px-2', sizeI === sizeFields.length - 1 ? 'pb-0' : 'pb-2']"
                          ><v-text-field
                            class="pl-0 pr-0"
                            dense
                            v-model="spaceDetails[sizeField]"
                            v-validate="
                              `required|decimal:2|min_value:0|max_value:999999`
                            "
                            :data-vv-name="sizeField"
                            :data-vv-as="sizeField"
                            :error-messages="errors.collect(`${sizeField}`)"
                            :name="sizeField"
                            :id="sizeField"
                            hide-details
                        /></v-col>
                        <v-col cols="2" :class="['pt-0 pl-0', sizeI === sizeFields.length - 1 ? 'pb-0' : 'pb-2']">(ft)</v-col>
                      </v-row>
                    </div>
                    <div class="pa-0" v-else-if="field.name == 'Door Size'">
                      <v-row
                        v-for="(doorSizeField, dIndex) in doorSizeFields"
                        :key="dIndex"
                        class="mt-0 pa-0"
                      >
                        <v-col cols="2" :class="['pt-0 pr-0', dIndex === doorSizeFields.length - 1 ? 'pb-0' : 'pb-2']">
                          {{ doorSizeField.name }}:
                        </v-col>
                        <v-col cols="2" :class="['pt-0 px-2', dIndex === doorSizeFields.length - 1 ? 'pb-0' : 'pb-2']"
                          ><v-text-field
                            class="pl-0 pr-0"
                            dense
                            v-model="spaceDetails[doorSizeField.value]"
                            v-validate="
                              `decimal:2|min_value:0|max_value:999999`
                            "
                            :data-vv-name="`Door ${doorSizeField.name}`"
                            :data-vv-as="`Door ${doorSizeField.name}`"
                            :error-messages="
                              errors.collect(`Door ${doorSizeField.name}`)
                            "
                            :name="`Door ${doorSizeField.name}`"
                            :id="`Door ${doorSizeField.name}`"
                            hide-details
                        /></v-col>
                        <v-col cols="2" :class="['pt-0 pl-0', dIndex === doorSizeFields.length - 1 ? 'pb-0' : 'pb-2']">(ft)</v-col>
                      </v-row>
                    </div>
                    <v-text-field
                      v-else-if="field.name == 'Set Rate'"
                      dense
                      hide-details
                      single-line
                      v-model="spaceDetails.setPrice"
                      prepend-inner-icon="mdi-currency-usd"
                      v-validate="
                        'required|decimal:2|max_value:999999|min_value:0'
                      "
                      name="Set Rate"
                      id="Set Rate"
                      label="Enter Set Rate"
                      :error-messages="errors.collect(`Set Rate`)"
                    >
                    </v-text-field>
                    <v-select
                      v-if="field.name == 'Website Category'"
                      :items="websiteCategories"
                      item-text="name"
                      item-value="id"
                      label="Select a website category"
                      v-model="spaceDetails.website_group"
                      name="Website Category"
                      id="Website Category"
                      hide-details
                      single-line
                      :error-messages="errors.collect('Website Category')"
                      data-vv-name="Website Category"
                      data-vv-as="Website Category"
                      v-validate="'required'"
                    >
                    </v-select>
                    <div v-else-if="field.name == 'Add Spaces'">
                      <div class="text-grey pb-4">
                        Add space numbers using a range AND/OR a list to create
                        new spaces.
                      </div>
                      <div class="font-weight-medium pb-1">
                        Option 1: Space Range (Sequential)
                      </div>
                      <div class="text-grey pb-1">
                        Add spaces using a range to create spaces in a
                        sequential order. Prefix and/or suffix are optional.
                      </div>
                      <v-row>
                        <v-col cols="3" class="pr-0">
                          <div class="font-weight-medium d-flex">Space Range
                            <hb-tooltip class="ml-1">
                              <template v-slot:body>
                                Enter Space Range
                              </template>
                            </hb-tooltip>
                          </div>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field
                            dense
                            hide-details
                            single-line
                            v-model="startRange"
                            v-validate="`min_value:0|max_value:999999`"
                            name="startRange"
                            id="startRange"
                            label="Space #"
                            @blur="!startRange ? endRange = null : ''"
                          />
                        </v-col>
                        <span cols="1" class="pa-3">-</span>
                        <v-col cols="2">
                          <v-text-field
                            dense
                            hide-details
                            single-line
                            v-validate="
                              startRange ? `min_value:${parseInt(startRange) +
                                1}|max_value:999999`: null
                            "
                            v-model="endRange"
                            name="endRange"
                            id="endRange"
                            :disabled="!startRange"
                            label="Space #"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3" class="pr-0">
                          <div class="font-weight-medium d-flex">Prefix / Suffix
                            <hb-tooltip>
                              <template v-slot:body>
                                Enter Prefix / Suffix
                              </template>
                            </hb-tooltip>
                          </div>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field
                            dense
                            hide-details
                            single-line
                            v-model="prefix"
                            name="Prefix"
                            id="Prefix"
                            label="Prefix"
                          />
                        </v-col>
                        <span cols="1" class="pa-3">-</span>
                        <v-col cols="2">
                          <v-text-field
                            dense
                            hide-details
                            single-line
                            v-model="suffix"
                            name="Suffix"
                            id="Suffix"
                            label="Suffix"
                          />
                        </v-col>
                      </v-row>
                      <v-textarea
                        :value="generatedSpaceRange"
                        :disabled="true"
                        dense
                        auto-grow
                        rows="1"
                        single-line
                        hide-details
                        label="Use the inputs above to generate a space range."
                        class="pt-2 pb-6"
                      ></v-textarea>
                      <div class="font-weight-medium pb-1">
                        Option 2: Space List (Unordered)
                      </div>
                      <div class="text-grey">
                        <span
                          >Add spaces using a comma separated list for unordered
                          spaces.</span
                        >
                        <v-textarea
                          class="py-4"
                          v-model="unorderedList"
                          single-line
                          hide-details
                          dense
                          rows="1"
                          @blur="removeEmptySpace()"
                          label="Add Spaces."
                          auto-grow
                        ></v-textarea>
                      </div>
                      <div class="font-weight-medium pt-2">
                        {{ getSpacesCount }} Spaces
                      </div>
                    </div>

                    <div v-else-if="field.name == 'Access Area'">
                      <div class="text-grey pb-4">
                        Assign an access area to this space.
                      </div>
                        <v-select
                          :items="accessAreas"
                          item-text="name"
                          item-value="id"
                          label="Select Access Area"
                          v-model="spaceDetails.area_id"
                          name="Access Area"
                          id="Access Area"
                          hide-details
                          single-line
                          :error-messages="errors.collect('Access Area')"
                          data-vv-name="Access Area"
                          data-vv-as="Access Area"
                          v-validate="'required'"
                        ></v-select>

                    </div>
                  </hb-form>
                </div>
            </v-stepper-content>
            <v-stepper-content class="pa-0" key="2-content" step="2">
              <amenity-selector
                v-if="stepper === 2"
                :amenityList="amenityList"
                @update="updateAmenityList"
              ></amenity-selector>
            </v-stepper-content>
          </v-stepper-items>
        </hb-stepper>
      </template>
      <template v-slot:right-actions>
        <span class="">
          <hb-btn
            v-if="stepper === 2"
            color="secondary"
            @click="back"
            >Back</hb-btn
          >
          <hb-btn color="primary" type="submit" :disabled="stepper === 2 && (isLoading($options.name) || !Object.entries(amenityList).length)" :loading="stepper === 2 && isLoading($options.name)" @click="next">
            {{ stepper === 2 ? "Save" : "Next"}}
          </hb-btn>
        </span>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AmenitySelector from "./AmenitySelector.vue";
import api from "../../../assets/api.js";
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
  name: "AddSpaces",
  props: {
    value: {
      type: Boolean
    }
  },
  components: {
    AmenitySelector
  },
  mixins: [notificationMixin],
  computed: {
    ...mapGetters({
      addedFeaturesList: "featureAndAmenitiesStore/addedFeaturesList",
      properties: "propertiesStore/filtered"
    }),
    dialog: {
      get() {
        return this.value;
      },
      set() {}
    },
    generatedSpaceRange() {
      let startRange = parseInt(this.startRange);
      let endRange = parseInt(this.endRange);
      if (startRange && endRange && endRange > startRange) {
        return Array(endRange - startRange + 1)
          .fill()
          .map((_, idx) => {
            let spaceNumber = parseInt(startRange) + idx;
            let prefix = this.prefix ? this.prefix : "";
            let suffix = this.suffix ? this.suffix : "";

            return prefix + spaceNumber + suffix;
          });
      }
      return null;
    },
    getSpacesCount() {
      let generatedListCount = this.generatedSpaceRange
        ? this.generatedSpaceRange.length
        : 0;
      let unorderedListCount = this.unorderedList
        ? this.unorderedList.split(",").filter(n => n && /[a-z0-9]/i.test(n)).length
        : 0;
      return generatedListCount + unorderedListCount;
    },
    getModalTitle() {
        let title = 'Add Spaces to ';
        if(this.properties.length === 1){
          let property = this.properties[0];
          if(property.number){
            title +=  property.number + ' - ';
          }
          title +=  this.$options.filters.formatAddress(property.Address, '-', true);
        }
        return title;
    }
  },
  data() {
    return {
      accessAreasEnabled: false,
      tabOptions: [
        { label: "Create Space Mix", key: 1 },
        { label: "Apply Features and Amenities", key: 2 }
      ],
      stepper: 1,
      maxActiveStep: 1,
      showConfirmationDialog: false,
      steps: [
        { number: 1, status: "step-active" },
        { number: 2, status: "step-disabled" }
      ],
      spaceDetails: {},
      spaceTypes: [
        { name: "Standard Storage", value: "storage" },
        { name: "Parking", value: "parking" }
      ],
      unitList: [],
      amenityOptions: [],
      spaceField: [
        { name: "Space Type", required: true },
        { name: "Size", required: true },
        { name: "Door Size" },
        {
          name: "Set Rate",
          required: true,
          tooltip:
            "A rate used as a consistent, seldom-changing rate used to gauge the overall financial potential of your property."
        },
        { name: "Website Category", required: true },
        {
          name: "Add Spaces",
          required: true,
          tooltip: "Spaces at the property"
        },
      ],
      sizeFields: ["Width", "Length", "Height"],
      doorSizeFields: [
        { name: "Width", value: "doorWidth" },
        { name: "Height", value: "doorHeight" }
      ],
      prefix: null,
      suffix: null,
      startRange: null,
      endRange: null,
      unorderedList: null,
      amenityList: {},
      websiteCategories: [],
      accessAreas: []
    };
  },
  methods: {
    ...mapActions({
      fetchFeatures: "featureAndAmenitiesStore/fetchFeatures"
    }),
    //Removes empty and element not having any alphabet or digit
    removeEmptySpace() {
      if(this.unorderedList) {
        this.unorderedList = this.unorderedList.split(",").filter(n => n && /[a-z0-9]/i.test(n)).join(',');
      }
    },
    async goToStep(stepNumber, previousStepCompleted) {
      const maxStep = 3;

      // Button click on last step
      if (stepNumber > maxStep) {
        return;
      }

      // User clicks on next step button (not on stepper)
      if (previousStepCompleted) {
        this.steps[stepNumber - 1].status = "step-active";
        this.steps[stepNumber - 2].status = "step-complete";
        this.stepper = stepNumber;
        this.maxActiveStep = stepNumber;
      } else {
        if (stepNumber === 1 || stepNumber <= this.maxActiveStep) {
          this.stepper = stepNumber;
          this.steps[stepNumber - 1].status = "step-active";

          for (let i = stepNumber + 1; i <= this.maxActiveStep; i++) {
            this.steps[i - 1].status = "step-editable";
          }

          for (let j = stepNumber - 1; j > 0; j--) {
            this.steps[j - 1].status = "step-complete";
          }
        }
      }
    },

    next() {
      this.$validator.validateAll().then(status => {
        let duplicateSpaceLabel;
        if (!this.spaceDetails.area_id) {
          this.showMessageNotification({ type: 'error', description: 'No Access Area found in property.'});
          return;
        }
        if (status) {
          if (this.stepper == 1) {
            //check for duplicate space labels
            if (
              this.generatedSpaceRange &&
              this.generatedSpaceRange.length > 0 &&
              this.unorderedList
            ) {
              const generatedList = this.generatedSpaceRange.map((item) => item.toLowerCase().trim())
              duplicateSpaceLabel = this.unorderedList
                .split(",")
                .filter(item => generatedList.indexOf(item.toLowerCase().trim()) !== -1);
            }
            if(this.unorderedList) {
                const generatedList = this.unorderedList.split(',').map((item) => item.toLowerCase().trim())
                let dupUnOrdered = generatedList.filter((e, i, a) => a.indexOf(e) !== i);
                if(dupUnOrdered && dupUnOrdered.length > 0) {
                  if (duplicateSpaceLabel ) {
                    duplicateSpaceLabel.push(dupUnOrdered) }
                  else{
                    duplicateSpaceLabel = dupUnOrdered;
                  }
                }
            }
            if (
              (!duplicateSpaceLabel ||
                (duplicateSpaceLabel && duplicateSpaceLabel.length == 0)) &&
              ((this.generatedSpaceRange &&
                this.generatedSpaceRange.length > 0) ||
                this.unorderedList)
            ) {
              this.goToStep(this.stepper + 1, true);
            } else {
              let errMsg = '';
              if (duplicateSpaceLabel && duplicateSpaceLabel.length > 0) {
                errMsg = `Please remove duplicate space numbers to continue. ${duplicateSpaceLabel} `;
              } else {
                 errMsg = `Add at least one space.`;
              }
              this.showMessageNotification({ description: errMsg });

            }
          } else {
            this.saveSpace();
          }
        }
      });
    },
    async saveSpace() {
      const amenityList = [];
      Object.entries(this.amenityList).forEach(amenities => {
        amenities[1].map(amenity => {
          amenityList.push({
            amenityPropertyId: amenity.property_amenity_mapping_id,
            amenity_id: amenity.amenity_id,
            value: amenity.property_default_value
          });
        });
      });
      this.unitList = [];
      if (this.generatedSpaceRange) {
        this.unitList = this.generatedSpaceRange.map(a => a);
      }
      this.unorderedList &&
        this.unorderedList.split(",").forEach(unit => {
          if(this.unitList) {
              this.unitList.push(unit.trim())
          } else{
            this.unitList = [unit.trim()];
          }
        });
      const data = {
        spaceDetails: {
          property_id: this.properties[0].id,
          ...this.spaceDetails
        },
        unitList: this.unitList,
        amenityList
      };
      api.post(this, api.CREATE_SPACES, data)
        .then((res) => {
          if (res.duplicates.length) {
            let errList = `Please remove duplicate space numbers to continue. ${res.duplicates}`
            this.showMessageNotification({ description: errList });
            this.stepper = 1;
          } else {
            this.$emit("close");
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },

    back() {
      this.goToStep(this.stepper - 1, false);
    },

    onCloseConfirmationDialog() {
      if (this.stepper == 1) {
        this.$emit("close");
      } else {
        this.showConfirmationDialog = true;
      }
    },
    updateAmenityList(data) {
      Object.entries(this.amenityList).map(amenities => {
        if (amenities[0] === data.category) {
          const newAmenities = amenities[1].map(amenity => ({
            ...amenity,
            property_default_value:
              amenity.property_amenity_mapping_id === data.id
                ? data.value
                : amenity.property_default_value
          }));
          this.amenityList[data.category] = newAmenities;
        }
      });
    },

    async fetchAmenities(space_type) {
      await this.fetchFeatures({
        property_id: this.properties[0].id,
        space_type
      });
      let featureCategoryMap = {};
      this.addedFeaturesList.forEach(feature => {
        let category = feature.property_category_name
          ? feature.property_category_name
          : feature.master_category_name;
        if (featureCategoryMap[category]) {
          featureCategoryMap[category].push(feature);
        } else {
          featureCategoryMap[category] = [feature];
        }
      });
      this.amenityList = featureCategoryMap;
    },

    async fetchwebsiteCategories() {
      await api.get(this, api.CATEGORIES, {type: this.spaceDetails.space_type}).then(r => {
          this.websiteCategories = r.categories;
      });
    },

    async fetchAccessAreas() {
      await api.get(this, api.PROPERTIES + this.properties[0].id + "/areas", {active: 1}).then(r => {
          this.accessAreas = r;
          if (!this.accessAreasEnabled) {
            if (this.accessAreas[0]?.id) {
              this.spaceDetails.area_id = this.accessAreas[0].id;
            } else {
              this.spaceDetails.area_id = null;
              this.showMessageNotification({ type: 'error', description: 'No Access Area found in property.'});
            }
          }
      });
    }
  },
  watch: {
    "errors.items"(val) {
      let sizeField = [];
      if(val && val.length) {
        const errorMsg = [];
        val.forEach((item) => {
          if (item.field === "Space Type" && item.rule === "required" ) {
            errorMsg.push("Space Type is missing a selection.")
          } else if (item.field === "Set Rate" && item.rule === "required" ) {
            errorMsg.push("Set Rate is missing a value.")
          } else if (item.field === "Website Category" && item.rule === "required" ) {
            errorMsg.push("Website Category is missing a selection.")
          } else if (item.field === "Width" && item.rule === "required" ) {
              sizeField.push('width');
          } else if (item.field === "Length" && item.rule === "required" ) {
            sizeField.push('length');
          } else if (item.field === "Height" && item.rule === "required" ) {
            sizeField.push('height');
          } else {
            errorMsg.push(item.msg);
          }
        })
        if (sizeField.length) {
          errorMsg.push(`Size is missing the ${sizeField.join(', ')}.`);
        }
        let desc = 'There are errors in your form, correct them before continuing.';
        this.showMessageNotification({type: 'error', description: desc, list: errorMsg});
      }
    },
    stepper() {
      if (this.stepper === 2) {
        this.fetchAmenities(this.spaceDetails.space_type);
      }
    }
  },
  created() {
    // this.fetchwebsiteCategories();
    api.get(this, api.PROPERTIES + this.properties[0].id + "/property-area-management").then(results => {
        this.accessAreasEnabled = results?.property_area_configuration.access_hours_upgrade === 1;
    }).catch(error => {
        this.accessAreasEnabled = false;
    }).finally(() => {
      if (this.accessAreasEnabled) this.spaceField.push({ name: "Access Area", required: true});
      this.fetchAccessAreas();
    });
  },
};
</script>

<style lang="scss" scoped>
.stepper-header {
  width: 500px;
  box-shadow: none;
}
.capitalize {
  text-transform: capitalize;
}
.text-grey {
  color: grey;
}
.align-right {
  float: right;
}
</style>
