<!-- BCT: This file is added by BCT for EP: 97 Bulk edit -->
<!-- BCT: This file is added by BCT for EP: 97 Bulk edit -->
<!-- BCT: This file is added by BCT for EP: 97 Bulk edit -->
<template>
  <div style="position: absolute; top:0; bottom: 64px; width: 100%; ">
    <v-card flat class="edit-panel" style="height: 100%; overflow-y: auto;">
      <div class="text-center px-10 py-10" v-if="featureListLoading && !featureListLoaded">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="featureListLoaded && !featureListLoading">
        <div class="border-bottom hb-text-night-light py-2 px-2 pl-4">
          Select the features and amenities that you want to change. Then, select the option you want to apply to each selected space.
        </div>
        <amenity-selector
          showCheckbox
          :amenityList="amenityList"
          :show-help-text="false"
          @update="updateDefaultValue"
          @updateAmenitySelection="updateAmenitySelection"
        ></amenity-selector>
      </div>
    </v-card>

    <hb-bottom-action-bar @close="$emit('cancel')" class="bottom-bar-height">
      <template v-slot:right-actions>
        <hb-btn color="primary" @click="save" :disabled="isLoading($options.name) || (selectedAmenities && selectedAmenities.length === 0)">
          <loader color="white" size="10px" v-if="isLoading($options.name)"></loader>
          Apply Selected Features and Amenities
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
import AmenitySelector from "../../assets/spaceManagementComponents/AmenitySelector.vue"
import { mapActions, mapGetters } from "vuex";
import api from '../../../assets/api';
import { EventBus } from '../../../EventBus.js';
import Loader from '../../assets/CircleSpinner.vue';
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
    name: "BulkEditFeaturesAndAmenities",
    components:{
      AmenitySelector,
      Loader,
    },
    data() {
      return {
        amenityList: {},
        updatedAmenities: [],
      }
    },
    props: ['items'],
    mixins: [notificationMixin],
    computed: {
      ...mapGetters({
        properties: "propertiesStore/filtered",
        featuresAmenities: "featureAndAmenitiesStore/addedFeaturesList",
        featureListLoading: "featureAndAmenitiesStore/addedFeaturesListLoading",
        featureListLoaded: "featureAndAmenitiesStore/addedFeaturesListLoaded",
      }),
      selectedAmenities() {
        return this.updatedAmenities.filter(a => a.selected);
      }
    },
    async created() {
      this.fetchAmenities();
      EventBus.$on('space_amenities_update', this.handleEvent);
    },
    methods: {
      ...mapActions({
        fetchFeaturesAmenities: "featureAndAmenitiesStore/fetchFeatures"
      }),

    async fetchAmenities() {
      await this.fetchFeaturesAmenities({property_id: this.properties[0].id, space_type: this.items[0].unit_type});
      let featureCategoryMap = {};
      this.updatedAmenities = [];
      this.featuresAmenities.forEach(feature => {
        this.updatedAmenities.push({amenity_id: feature.amenity_id, amenity_property_id: feature.property_amenity_mapping_id, value: feature.property_default_value, selected: false});
        let category = feature.property_category_name
          ? feature.property_category_name
          : feature.master_category_name;
        if (featureCategoryMap[category]) {
          featureCategoryMap[category].push(feature);
        } else {
          featureCategoryMap[category] = [feature];
        }
      });
      this.amenityList = featureCategoryMap;
    },

    updateAmenitySelection(data) {
      const idx = this.updatedAmenities.findIndex(a => data.id === a.amenity_property_id);
      this.updatedAmenities[idx].selected = data.value;
    },

    updateDefaultValue(data) {
      this.updatedAmenities.forEach(a => {
        if (data.id === a.amenity_property_id) {
          a.value = data.value;
        }
      })
    },

    save() {
      let body = {
        units: this.items.map(unit => unit.unit_id),
        amenities: this.selectedAmenities.map(a => ({amenity_id: a.amenity_id, amenity_property_id: a.amenity_property_id, value: a.value}))
      };
      api.post(this, api.SPACE_MANAGEMENT + 'space-amenities', body).then(r => {
        this.updatedAmenities = [];
      }).
      catch(e => {
        this.showMessageNotification({type: 'error', description: 'Something went wrong.!'});
      })

    },

    handleEvent() {
      let spaces = this.items.map(a => a.unit_number);
      this.showMessageNotification({type: 'success', description: 'Features and Amenities have been updated for Space ' + spaces.join(', ') + '.'});
      EventBus.$emit('unit_edited');
      this.$emit('cancel');
    }

  },
  destroyed() {
    EventBus.$off('space_amenities_update');
  },
};
</script>

<style lang="scss" scoped>
  .bottom-bar-height {
    ::v-deep .hb-bab-wrapper {
      height: 63px !important;
    }
  }
</style>
